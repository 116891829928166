
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import { State as StateStore } from "../store";
import * as Api from "@/types/graphql";

@Component({
  watch: {
    selectedAreaState(val) {
      this.selectedArea = val;
    },
  },
})
export default class AreaSelector extends Vue {
  @State((state: StateStore) => state.data.areas) areas: Api.CodeDecode[];
  @State((state: StateStore) => state.data.selectedArea)
  selectedAreaState: Api.CodeDecode;

  loading = false;

  search = "";
  areaRules = [(v) => !!v || "Selezionare un Area"];
  selectedArea = null;

  mounted() {
    this.selectedArea = this.selectedAreaState;
  }

  onChange() {
    this.search = "";
    this.$emit("selected", this.selectedArea);
  }

  isSelected(area) {
    if (!_.isNil(this.selectedArea)) {
      return this.selectedArea.guid == area.guid;
    }
  }

  filterArea(item, queryText) {
    if ((queryText as string)?.length < 3) return false;

    return item.des
      .toString()
      .toLowerCase()
      .includes(queryText.toString().toLowerCase());
  }
}
