
import AreaSelector from "@/components/AreaSelector.vue";
import CompaniesSelector from "@/components/CompaniesSelector.vue";
import DatePicker from "@/components/DatePicker.vue";
import GenderSelector from "@/components/GenderSelector.vue";
import GroupsSelector from "@/components/GroupsSelector.vue";
import Modal from "@/components/Modal.vue";
import SelectedGroups from "@/components/SelectedGroups.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import ServicesSelector from "@/components/ServicesSelector.vue";
import HeadquarterSelector from "@/components/HeadquarterSelector.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import { getToday } from "@/plugins/moment";
import * as Api from "@/types/graphql";
import { ServiceStatus, StatusCodes } from "@/utils/status";
import gql from "graphql-tag";
import _ from "lodash";
import validator from "validator";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import { checkUserRole, RoleCodes } from "../utils/roles";
import { associateData, TAX_CODE_REGEX } from "../utils/user";

@Component({
  components: {
    Modal,
    ServicesSelector,
    AreaSelector,
    SelectedServices,
    UsersSelector,
    CompaniesSelector,
    GenderSelector,
    GroupsSelector,
    SelectedGroups,
    DatePicker,
    HeadquarterSelector,
  },
  watch: {
    user(val) {
      if (val.company == null) {
        this.newUser.status = null;
      } else {
        this.newUser.status = this.userstatus[0];
      }
    },
  },
})
export default class CreateUserModal extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.selectedServices)
  selectedServices: Api.Service[];
  @State((state: StateStore) => state.data.selectedGroups)
  selectedGroups: Api.CodeDecodeChild[];
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Getter getRole: (code: string) => Api.Role;
  @Action fetchRolesList;
  @Action updateUsersServices;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation removeSelectedService;
  @Mutation resetSelectedServices;
  @Mutation setSelectedUsers;
  @Mutation resetSelectedUsers;
  @Mutation setSelectedArea; 
  @Mutation setSelectedHeadquarter;
  @Mutation resetSelectedGroups;

  @Prop({ default: false })
  onlyUser: boolean;

  @Prop({ default: false })
  teacher: boolean;

  loading = false;

  isUserField = false;
  isAdminField = false;
  showCompanySection = false;
  showAdminSection = false;
  disableReferentSelection = false;
  showGroupsSelector = false;

  isFormValid = false;

  showCreateUserModal = null;
  roles = [];
  companies = [];
  userstatus = [];
  adminstatus = [];

  associatedItems = associateData;

  newUser = {
    name: null,
    surname: null,
    email: null,
    phone: null,
    role: null,
    referent: null,
    company: null,
    status: null,
    macroarea: null,
    birthday: null,
    gender: null,
    taxCode: null,
    associated: null,
    associatedDate: null,
    isTeacher: false,
    headquarter: null,
  };

  nameRules = [(v) => !!v || "Inserire un nome"];
  surnameRules = [(v) => !!v || "Inserire il cognome"];
  emailRules = [
    (v) => !!v || "Inserire la mail",
    (v) => !!validator.isEmail(v ? v : "") || "Email non valida",
  ];
  phoneRules = [];
  roleRules = [(v) => !!v || "Selezionare il ruolo"];
  adminStatusRules = [(v) => !!v || "Selezionare una voce"];
  facilitatorRules = [
    (v) => !!this.newUser.referent || "Selezionare il Referente CNA",
  ];
  genderRules = [(v) => !!v || "Seleziona un valore"];
  birthdayRules = [(v) => !!v || "Inserire la data di nascita"];
  taxCodeRules = [(v) => !!v || "Inserire il codice fiscale"];
  associatedRules = [(v) => v == 0 || v == 1 || "Seleziona un opzione"];

  async mounted() {
    if (!this.onlyUser) {
      await this.fetchRolesList();
      //await this.getCompanies();
    }
    this.resetValues();
    this.roleSelected();
  }

  resetValues() {
    (this.$refs.createUserForm as Vue & { reset: () => void })?.reset();
    this.showCompanySection = false;
    this.showAdminSection = false;
    this.roles = [];
    this.userstatus = [];
    this.adminstatus = [];
    this.roles = [this.getRole(RoleCodes.USER)];
    if (checkUserRole(this.user, [RoleCodes.ADMIN1])) {
      this.roles.push(this.getRole(RoleCodes.ADMIN2));
    }
    this.userstatus.push(this.getStatus(StatusCodes.USER_DIP_AZ));
    this.userstatus.push(this.getStatus(StatusCodes.USER_REF_AZ));
    this.adminstatus.push(this.getStatus(StatusCodes.ADMIN2_RES_AREA));
    this.adminstatus.push(this.getStatus(StatusCodes.ADMIN2_RES_SER));
    this.resetSelectedServices();
    this.resetSelectedGroups();
    this.setSelectedArea(null);
    this.setSelectedHeadquarter(null);

    this.newUser.role = this.getRole(RoleCodes.USER).guid;

    if (this.onlyUser) {
      this.newUser.referent = this.user;
      this.newUser.role = this.getRole(RoleCodes.USER).guid;
      this.disableReferentSelection = true;
      this.setSelectedUsers([this.user]);
    }
    this.newUser.isTeacher = this.teacher;
    this.newUser.headquarter = null;

    this.loading = false;
  }

  roleSelected() {
    if (this.newUser.role == this.getRole(RoleCodes.USER).guid) {
      this.showCompanySection = true;
      this.showAdminSection = false;
      if (!this.onlyUser) {
        this.disableReferentSelection = false;
        this.resetSelectedUsers();
      }
      this.isUserField = true;
      this.isAdminField = false;
    }
    if (this.newUser.role == this.getRole(RoleCodes.ADMIN2).guid) {
      this.showCompanySection = false;
      this.showAdminSection = true;
      this.newUser.referent = this.user;
      this.disableReferentSelection = true;
      this.setSelectedUsers([this.user]);
      this.isUserField = false;
      this.isAdminField = true;
    }
  }

  onUserAssociatedChange(associated) {
    this.newUser.associated = associated;
    if (!associated) {
      this.newUser.associatedDate = null;
    }
  }

  onUserAssociatedDateSelected(date) {
    this.newUser.associatedDate = date;
  }

  getRolesFilter() {
    return [
      this.getRole(RoleCodes.ADMIN1).guid,
      this.getRole(RoleCodes.ADMIN2).guid,
    ];
  }
  showCompanyPart() {
    return this.showCompanySection && !this.onlyUser;
  }

  onCompanySelected(company) {
    if (_.isNil(company)) {
      this.newUser.company = null;
    } else {
      this.newUser.company = company.guid;
    }
  }

  showServicesPart() {
    return (
      this.newUser.status == this.getStatus(StatusCodes.ADMIN2_RES_SER).guid
    );
  }

  showAreasPart() {
    return (
      this.newUser.status == this.getStatus(StatusCodes.ADMIN2_RES_AREA).guid
    );
  }

  areaSelected(area) {
    this.resetSelectedGroups();
    this.newUser.macroarea = area.guid;
    this.setSelectedArea(area);
    this.showGroupsSelector = true;
  }

  onHeadquarterSelected(headquarter) {
    if (_.isNil(headquarter)) {
      this.newUser.headquarter = null;
    } else {
      this.newUser.headquarter = headquarter.guid;
    }
  }

  onClose(reset) {
    if (reset) {
      this.resetValues();
    }
    this.$emit("close");
  }

  async getCompanies() {
    if (_.isEmpty(this.companies)) {
      const companiesRes = await this.$apollo.query({
        query: gql`
          query {
            CompanyList {
              guid
              ragsoc
            }
          }
        `,
        fetchPolicy: "no-cache",
      });
      this.companies = companiesRes.data.CompanyList;
    }
  }

  async createUser() {
    if (
      (
        this.$refs.createUserForm as Vue & {
          validate: () => boolean;
        }
      ).validate()
    ) {
      this.loading = true;
      let res;
      if (this.newUser.role == this.getRole(RoleCodes.ADMIN2).guid) {
        await this.createAdminLevel2();
      } else {
        if (this.newUser.company == null) {
          this.setUserStatus();
          res = await this.$apollo.mutate({
            mutation: gql`
              mutation user_insert(
                $email: String
                $name: String
                $surname: String
                $phone: String
                $role: String
                $facilitator: String
                $status: String
                $birthday: String
                $gender: String
                $taxCode: String
                $associated: Int
                $associatedYear: Date
                $isTeacher: Int
              ) {
                insertUser(
                  email: $email
                  name: $name
                  surname: $surname
                  phone: $phone
                  role_guid: $role
                  facilitator_guid: $facilitator
                  userstatus_guid: $status
                  birth_date: $birthday
                  gender: $gender
                  tax_code: $taxCode
                  associated: $associated
                  associated_year: $associatedYear
                  is_teacher: $isTeacher
                )
              }
            `,
            variables: {
              email: this.newUser.email,
              name: _.capitalize(this.newUser.name),
              surname: _.capitalize(this.newUser.surname),
              phone: this.newUser.phone,
              role: this.newUser.role,
              facilitator: this.newUser.referent.guid,
              status: this.newUser.status,
              birthday: this.newUser.birthday,
              gender: this.newUser.gender,
              taxCode: this.newUser.taxCode,
              associated: this.newUser.associated,
              associatedYear: this.newUser.associatedDate,
              isTeacher: this.newUser.isTeacher ? 1 : 0,
            },
          });
        } else {
          res = await this.$apollo.mutate({
            mutation: gql`
              mutation user_insert(
                $email: String
                $name: String
                $surname: String
                $phone: String
                $role: String
                $facilitator: String
                $company: String
                $status: String
                $birthday: String
                $gender: String
                $taxCode: String
                $associated: Int
                $associatedYear: Date
                $isTeacher: Int
              ) {
                insertUser(
                  email: $email
                  name: $name
                  surname: $surname
                  phone: $phone
                  role_guid: $role
                  facilitator_guid: $facilitator
                  company_guid: $company
                  userstatus_guid: $status
                  birth_date: $birthday
                  gender: $gender
                  tax_code: $taxCode
                  associated: $associated
                  associated_year: $associatedYear
                  is_teacher: $isTeacher
                )
              }
            `,
            variables: {
              email: this.newUser.email,
              name: _.capitalize(this.newUser.name),
              surname: _.capitalize(this.newUser.surname),
              phone: this.newUser.phone,
              role: this.newUser.role,
              facilitator: this.newUser.referent.guid,
              company: this.newUser.company,
              status: this.newUser.status,
              birthday: this.newUser.birthday,
              gender: this.newUser.gender,
              taxCode: this.newUser.taxCode,
              associated: this.newUser.associated,
              associatedYear: this.newUser.associatedDate,
              isTeacher: this.newUser.isTeacher ? 1 : 0,
            },
          });
        }
        if (res.data.insertUser) {
          this.$emit("userCreated", res.data.insertUser);
          this.showSuccess("Utente creato correttamente");
          this.onClose(true);
        } else {
          this.loading = false;
          this.showError("Errore durante creazione utente");
        }
      }
    }
  }

  async createAdminLevel2() {
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation user_insert(
          $email: String
          $name: String
          $surname: String
          $phone: String
          $role: String
          $facilitator: String
          $status: String
          $macroarea: String
          $groups: [String]
          $birthday: String
          $gender: String
          $taxCode: String
          $isTeacher: Int
          $headquarter: String
        ) {
          insertUser(
            email: $email
            name: $name
            surname: $surname
            phone: $phone
            role_guid: $role
            facilitator_guid: $facilitator
            userstatus_guid: $status
            macroarea_guid: $macroarea
            macroarea_sottogruppo: $groups
            birth_date: $birthday
            gender: $gender
            tax_code: $taxCode
            is_teacher: $isTeacher
            headquarter_guid: $headquarter
          )
        }
      `,
      variables: {
        email: this.newUser.email,
        name: _.capitalize(this.newUser.name),
        surname: _.capitalize(this.newUser.surname),
        phone: this.newUser.phone,
        role: this.newUser.role,
        facilitator: this.newUser.referent.guid,
        status: this.newUser.status,
        macroarea: this.newUser.macroarea,
        groups: _.map(this.selectedGroups, (group) => {
          return group.guid;
        }),
        birthday: this.newUser.birthday,
        gender: this.newUser.gender,
        taxCode: this.newUser.taxCode,
        isTeacher: this.newUser.isTeacher ? 1 : 0,
        headquarter: this.newUser.headquarter,
      },
    });
    if (res.data.insertUser) {
      const servicesAssigned = await this.updateUsersServices({
        users: [res.data.insertUser],
        services: _.map(this.selectedServices, "guid"),
        status: ServiceStatus.ASSIGNED,
      });
      this.$emit("userCreated");
      this.showSuccess("Utente creato correttamente");
      if (!servicesAssigned) {
        this.showError("Utente creato - Servizi non associati");
      }
      this.onClose(true);
    } else {
      this.loading = false;
      this.showError("Errore durante creazione utente");
    }
  }

  setUserStatus() {
    if (this.newUser.role == this.getRole(RoleCodes.USER).guid) {
      if (this.newUser.company == null) {
        this.newUser.status = this.getStatus(StatusCodes.USER_ACTIVE).guid;
      }
    }
  }

  getUserMaxDate() {
    return getToday();
  }
}
