
import * as Api from "@/types/graphql";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";

@Component({
  components: {},
  watch: {
    selectedUnione(val) {
      this.items = val;
    }
  },
})
export default class SelectedUnione extends Vue {
  @State((state: StateStore) => state.data.selectedUnione)
  selectedUnione: string[];
  @Mutation removeSelectedUnione;
  @Mutation resetSelectedUnione;

  @Prop({ default: true })
  enableDelete: boolean;

  items = [];

  mounted() {
    this.items = this.selectedUnione;
  }

  beforeDestroy() {
    this.resetSelectedUnione();
  }
}
