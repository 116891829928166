export enum CompanyStatusCodes {
  ACTIVE = "ACTIVE",
  CEASED = "CEASED",
}

export interface CompanyStatus {
  code: CompanyStatusCodes;
  des: string;
}

export const statuses: CompanyStatus[] = [
  {
    code: CompanyStatusCodes.ACTIVE,
    des: "Attiva",
  },
  {
    code: CompanyStatusCodes.CEASED,
    des: "Cessata",
  },
];

export function isCompanyActive(code: CompanyStatusCodes) {
  return code == CompanyStatusCodes.ACTIVE;
}

export function isCompanyCeased(code: CompanyStatusCodes) {
  return code == CompanyStatusCodes.CEASED;
}
