
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import CompaniesTable from "@/components/CompaniesTable.vue";
import CreateCompany from "@/components/CreateCompany.vue";
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: { Btn, Card, CreateCompany, CompaniesTable },
})
export default class AddCompanyView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;

}
