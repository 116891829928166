
import AtecoSelector from "@/components/AtecoSelector.vue";
import DatePicker from "@/components/DatePicker.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import HeadquarterSelector from "@/components/HeadquarterSelector.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import Card from "@/components/Card.vue";
import Btn from "@/components/Btn.vue";
import * as Api from "@/types/graphql";
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter, Mutation, State } from "vuex-class";
import validator from "validator";
import { State as StateStore } from "../store";
import CreateUserModal from "@/components/CreateUserModal.vue";
import { RoleCodes } from "@/utils/roles";
import _ from "lodash";
import gql from "graphql-tag";
import { StatusCodes } from "@/utils/status";
import { RoutesNames } from "@/router";

@Component({
  components: {
    Card,
    Btn,
    AtecoSelector,
    CreateUserModal,
    DatePicker,
    UsersSelector,
    HeadquarterSelector,
    SelectedUsers,
  },
  watch: {
    newCompanyCompany(val) {
      if (val == null) {
        this.newCompanyStatus = null;
      } else {
        this.newCompanyStatus = this.userstatus[0];
      }
    },
  },
})
export default class CreateCompany extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.users) users: Api.User;
  @State((state: StateStore) => state.data.selectedUsers)
  selectedUsers: Api.Service[];
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Getter getRole: (code: string) => Api.Role;
  @Action getUsers;
  @Action fetchRolesList;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation setSelectedUsers;
  @Mutation setSelectedHeadquarter;
  @Mutation resetSelectedUsers;

  showCreateUserModal = null;

  loading = false;

  panels = [0, 1];

  isFormValid = false;

  newCompany = {
    name: null,
    taxCode: null,
    vat: null,
    email: null,
    address: null,
    city: null,
    province: null,
    phone: null,
    pec: null,
    ateco: null,
    union: null,
    sector: null,
    code: null,
    associated: null,
    associatedDate: null,
    notes: null,
    trade: null,
    headquarter: null,
  };

  associatedItems = [
    { label: "Associata", value: 1 },
    { label: "Non Associata", value: 0 },
  ];

  search = "";

  atecoList = [];

  nameRules = [(v) => !!v || "Inserire una ragione fiscale"];
  taxCodeRules = [(v) => !!v || "Inserire il Codice Fiscale"];
  vatRules = [];
  atecoRules = [(v) => !!v || "Selezionare un codice Ateco"];
  emailRules = [(v) => !!validator.isEmail(v ? v : "") || "Email non valida"];
  addressRules = [(v) => !!v || "Inserire l'indirizzo"];
  cityRules = [(v) => !!v || "Inserire il comune"];
  provinceRules = [(v) => !!v || "Inserire la provincia"];
  pecRules = [(v) => !!validator.isEmail(v ? v : "") || "PEC non valida"];
  associatedRules = [(v) => v == 1 || v == 0 || "Seleziona un opzione"];

  async mounted() {
    this.resetValues();
  }

  resetValues() {
    (this.$refs.createCompanyForm as Vue & { reset: () => void })?.reset();
    this.setSelectedHeadquarter(null);
    this.resetSelectedUsers();
  }

  atecoSelected(ateco) {
    this.newCompany.ateco = ateco;
    this.newCompany.union = ateco.des_unione;
    this.newCompany.sector = ateco.des_settore;
  }

  associatedDateSelected(date) {
    this.newCompany.associatedDate = date;
  }

  getRolesFilter() {
    return [this.getRole(RoleCodes.USER).guid];
  }

  onHeadquarterSelected(headquarter) {
    if (_.isNil(headquarter)) {
      this.newCompany.headquarter = null;
    } else {
      this.newCompany.headquarter = headquarter.guid;
    }
  }

  async fetchUsers(newUserGuid) {
    await this.getUsers({
      roleFilter: [this.getRole(RoleCodes.USER).guid],
    });
    const newUser = _.find(this.users, { guid: newUserGuid });
    if (!_.isUndefined(newUser)) {
      this.setSelectedUsers([newUser]);
    }
  }

  async saveCompany() {
    console.log(this.selectedUsers);
    if (
      (this.$refs.createCompanyForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      this.loading = true;
      const createCompanyRes = await this.$apollo.mutate({
        mutation: gql`
          mutation insertCompany(
            $companyCode: String
            $ragsoc: String
            $vat: String
            $taxCode: String
            $emailpec: String
            $email: String
            $phone: String
            $associated: Int
            $notes: String
            $address: String
            $city: String
            $prov: String
            $atecoCode: String
            $trade: String
            $headquarter: String
          ) {
            insertCompany(
              id_company: $companyCode
              ragsoc: $ragsoc
              vat_number: $vat
              tax_code: $taxCode
              emailpec: $emailpec
              email: $email
              phone: $phone
              associated: $associated
              notes: $notes
              address: $address
              city: $city
              prov: $prov
              ateco_code: $atecoCode
              trade: $trade
              headquarter_guid: $headquarter
            )
          }
        `,
        variables: {
          companyCode: this.newCompany.code,
          ragsoc: _.capitalize(this.newCompany.name),
          vat: this.newCompany.vat,
          taxCode: this.newCompany.taxCode,
          emailpec: this.newCompany.pec,
          email: this.newCompany.email,
          phone: this.newCompany.phone,
          associated: this.newCompany.associated,
          notes: this.newCompany.notes ? this.newCompany.notes : " ",
          address: _.capitalize(this.newCompany.address),
          city: _.capitalize(this.newCompany.city),
          prov: this.newCompany.province,
          atecoCode: this.newCompany.ateco.code.replaceAll(".", ""),
          trade: this.newCompany.trade,
          headquarter: this.newCompany.headquarter,
        },
      });
      if (createCompanyRes.data.insertCompany) {
        await this.setUsersAsReferent(createCompanyRes.data.insertCompany);
        this.loading = false;
        this.showSuccess("Azienda inserita correttamente");
        this.$router.push({
          name: RoutesNames.companiesManageDetails,
          params: { companyGuid: createCompanyRes.data.insertCompany },
        });
      } else {
        this.loading = false;
        this.showError("Errore durante inserimento azienda");
      }
    }
  }

  async setUsersAsReferent(companyGuid) {
    if (this.selectedUsers.length > 0) {
      const companyRes = await this.$apollo.mutate({
        mutation: gql`
          mutation put($guid: ID!, $referents: [UserInput]) {
            updateCompany(guid: $guid, referents: $referents)
          }
        `,
        variables: {
          guid: companyGuid,
          referents: this.selectedUsers.map((user) => {
            return { guid: user.guid };
          }),
        },
      });

      this.selectedUsers.forEach(async (user) => {
        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation put($guid: ID!, $companyGuid: String) {
              updateUser(guid: $guid, company_guid: $companyGuid)
            }
          `,
          variables: {
            guid: user.guid,
            companyGuid: companyGuid,
          },
        });
        this.loading = false;
        if (res.data.updateUser) {
          await this.updateStatus((user as unknown) as Api.User);
        } else {
          this.loading = false;
          this.showError("Errore aggiornamento referente azienda");
        }
      });
    }
  }

  async updateStatus(user: Api.User) {
    const res = await this.$apollo.mutate({
      mutation: require("@/graphql/setUserStatus.graphql"),
      variables: {
        guid: user.guid,
        role: user.role.guid,
        status: this.getStatus(StatusCodes.USER_REF_AZ).guid,
      },
    });

    if (!res.data.changeStatusUser) {
      this.loading = false;
      this.showError("Errore aggiornamento referente azienda");
    }
  }
}
