
import * as Api from "@/types/graphql";
import _ from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {},
  watch: {
    selectedHeadquarterState(val) {
      this.selectedHeadquarter = val;
    },
  },
})
export default class HeadquarterSelector extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.headquarters)
  headquartersState: Api.Headquarter[];
  @State((state: StateStore) => state.data.selectedHeadquarter)
  selectedHeadquarterState: Api.CodeDecode;

  options = [];
  selectedHeadquarter = null;

  @Prop({default: "Seleziona una sede"})
  label

  async mounted() {
    this.initItems();
  }

  initItems() {
    this.options = this.headquartersState;
    this.selectedHeadquarter = this.selectedHeadquarterState;
  }

  filter(item, queryText) {
    if (item.header || (queryText as string)?.length < 3) return false;

    return item.city
      .toString()
      .toLowerCase()
      .includes(queryText.toString().toLowerCase());
  }

  isSelected(headquarter) {
    if (this.selectedHeadquarter) {
      return headquarter.guid == this.selectedHeadquarter.guid;
    }
    return false;
  }

  onChange(headquarter) {
    this.$emit("selected", headquarter);
  }
}
