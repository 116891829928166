
import * as Api from "@/types/graphql";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  watch: {
    selectedUnioneState(val, prev) {
      this.selectedUnione = val;
    },
  },
})
export default class UnioneSelector extends Vue {
  @State((state: StateStore) => state.data.selectedUnione)
  selectedUnioneState: Api.User[];
  @Mutation setSelectedUnione;

  loading = false;
  @Prop({ default: true })
  multiple: boolean;

  search = "";
  unioneList = [];
  selectedUnione = [];

  async mounted() {
    this.unioneList = [];
    await this.getUnioneList();
  }

  onChange() {
    this.search = "";
    this.setSelectedUnione(this.selectedUnione);
  }

  async getUnioneList() {
    const unioneRes = await this.$apollo.query({
      query: gql`
        query {
          AtecoList {
            des_unione
          }
        }
      `,
      fetchPolicy: "no-cache",
    });

    this.unioneList = _.map(unioneRes.data.AtecoList, 'des_unione');
  }

  getMenuProps() {
    return {
      closeOnContentClick: !this.multiple,
    };
  }

  isSelected(unione) {
    return _.includes(this.selectedUnioneState, unione)
  }

  filterUnione(item, queryText) {
    if ((queryText as string)?.length < 3) return false;

    return (
      item.includes(queryText.toString().toLowerCase())
    );
  }
}
