
import * as Api from "@/types/graphql";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";

@Component({
  components: {},
  watch: {
    selectedAteco(val) {
      this.items = val;
    }
  },
})
export default class SelectedAteco extends Vue {
  @State((state: StateStore) => state.data.selectedAteco)
  selectedAteco: Api.Ateco[];
  @Mutation removeSelectedAteco;
  @Mutation resetSelectedAteco;

  @Prop({ default: true })
  enableDelete: boolean;

  items = [];

  mounted() {
    this.items = this.selectedAteco;
  }

  beforeDestroy() {
    this.resetSelectedAteco();
  }
}
