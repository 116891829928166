
import { CompaniesListTypes } from "@/store/modules/data";
import * as Api from "@/types/graphql";
import _ from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {},
  watch: {
    selectedCompaniesState(val, prev) {
      this.selectedCompanies = val;
    },
    hiddenCompanies(val, prev) {
      this.initItems();
    },
  },
})
export default class CompaniesSelector extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.companies)
  companiesState: Api.Company[];
  @State((state: StateStore) => state.data.companiesLoading)
  companiesLoading: boolean;
  @State((state: StateStore) => state.data.selectedCompanies)
  selectedCompaniesState: Api.Company[];
  @Action getCompanies;
  @Mutation addSelectedCompanies;
  @Mutation removeSelectedCompany;
  @Mutation setSelectedCompanies;

  @Prop({ default: "Seleziona un azienda" })
  label: string;
  @Prop({ default: true })
  multiple: boolean;
  @Prop()
  selectedCompany: Api.Company;
  @Prop()
  hiddenCompanies: Api.Company[];
  @Prop({ default: false })
  disabled: boolean;

  options = [];
  selectedCompanies = [];
  selected = [];

  async mounted() {
    await this.getCompanies({ type: CompaniesListTypes.Selector });
    this.initItems();
    if (!this.multiple && this.selectedCompaniesState.length == 1) {
      this.selectedCompanies = this.selectedCompaniesState;
    }
  }

  getMenuProps() {
    return {
      closeOnContentClick: !this.multiple,
    };
  }

  initItems() {
    if (_.isNil(this.hiddenCompanies)) {
      this.initOptions(this.companiesState);
    } else {
      const hiddenGuids = _.map(this.hiddenCompanies, "guid");
      this.initOptions(
        _.filter(this.companiesState, (company) => {
          return !hiddenGuids.includes(company.guid);
        })
      );
    }
  }

  async initOptions(companies: Api.Company[]) {
    this.options = companies;
  }

  filter(item, queryText) {
    if (item.header || (queryText as string)?.length < 3) return false;

    return item.ragsoc
      .toString()
      .toLowerCase()
      .includes(queryText.toString().toLowerCase());
  }

  isSelected(company) {
    return !_.isNil(
      _.find(this.selectedCompaniesState, (c) => {
        return c.guid == company.guid;
      })
    );
  }

  onChange(companies) {
    if (!this.multiple) {
      if (_.isEmpty(companies)) {
        this.setSelectedCompanies([]);
        this.$emit("selected", null);
      } else if (_.last(companies) instanceof Object) {
        this.setSelectedCompanies([_.last(companies)]);
        this.$emit("selected", _.last(companies));
      }
    } else {
      this.setSelectedCompanies(
        _.filter(companies, (company) => {
          return company instanceof Object;
        })
      );
    }
  }
  capitalize(text) {
    return _.capitalize(text);
  }
}
