
import * as Api from "@/types/graphql";
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import AtecoSelector from "@/components/AtecoSelector.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import gql from "graphql-tag";
import _ from "lodash";
import { Prop } from "vue-property-decorator";
import { RoleCodes } from "@/utils/roles";
import { formatAtecoCode } from "@/utils/ateco";

@Component({
  components: {
    Modal,
    Avatar,
    AtecoSelector,
    SelectedUsers,
  },
  watch: {},
})
export default class EditAtecoCompanyModal extends Vue {
  @Getter getRole: (code: string) => Api.Role;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation resetSelectedUsers;
  @Mutation resetSelectedCompanies;
  @Mutation setSelectedCompanies;

  @Prop()
  company;

  loading = false;

  selectedAteco = null;

  mounted() {
    this.resetValue();
  }

  resetValue() {
    this.selectedAteco = null;
  }

  getAtecoCode(company) {
    return formatAtecoCode(company.ateco.code);
  }

  async save() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation company_update($guid: ID!, $atecoCode: String) {
          updateCompany(guid: $guid, ateco_code: $atecoCode)
        }
      `,
      variables: {
        guid: this.company.guid,
        atecoCode: this.selectedAteco.code.replaceAll(".", ""),
      },
    });
    this.loading = false;
    if (res.data.updateCompany) {
      this.$emit("success");
      this.showSuccess("Codice Ateco modificato correttamente");
      this.onClose();
    } else {
      this.showError("Errore durante modifica Codice Ateco");
    }
  }

  onClose() {
    this.$emit("close");
    this.resetValue();
  }
}
