
import * as Api from "@/types/graphql";
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import DatePicker from "@/components/DatePicker.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import gql from "graphql-tag";
import _ from "lodash";
import { getToday } from "@/plugins/moment";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    Modal,
    Avatar,
    DatePicker,
  },
  watch: {
    company(val) {
      if (val) {
        this.editCompany = _.clone(val);
      }
    },
  },
})
export default class HandleCompanyAssociatingModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;

  @Prop()
  company: Api.Company;

  editCompany: Api.Company = null;

  loading = false;

  associatedDate = null;

  mounted() {
    this.editCompany = _.clone(this.company);
  }

  showAssociatedOrNot() {
      if (this.editCompany.associated) {
        return "associata a CNA";
      } else {
        return "non associata a CNA";
      }
    
  }

  showAssociatingAction() {
      if (this.editCompany.associated) {
        return "annullare l'associazione a CNA?";
      } else {
        return "associare l'azienda a CNA?";
      }
    
  }

  showDatePicker() {
      return !this.editCompany?.associated;
    
  }

  getPreselectedDate() {
    this.associatedDateSelected(getToday());
    return getToday();
  }

  associatedDateSelected(date) {
    this.associatedDate = date;
  }

  async save() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation company_update($guid: ID!, $associated: Int) {
          updateCompany(guid: $guid, associated: $associated)
        }
      `,
      variables: {
        guid: this.editCompany.guid,
        associated: this.editCompany.associated ? 0 : 1,
      },
    });
    this.loading = false;
    if (res.data.updateCompany) {
      this.$emit("success");
      this.showSuccess("Associazione a CNA aggiornata");
      this.onClose();
    } else {
      this.showError("Errore durante gestione associazione a CNA");
    }
  }

  onClose() {
    this.$emit("close");
  }
}
