
import * as Api from "@/types/graphql";
import AtecoSelector from "@/components/AtecoSelector.vue";
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import SelectedAteco from "@/components/SelectedAteco.vue";
import SelectedUnione from "@/components/SelectedUnione.vue";
import EditAtecoCompanyModal from "@/components/EditAtecoCompanyModal.vue";
import EditStatusCompanyModal from "@/components/EditStatusCompanyModal.vue";
import DeleteCompanyModal from "@/components/DeleteCompanyModal.vue";
import AssociateReferentToCompanyModal from "@/components/AssociateReferentToCompanyModal.vue";
import UnioneSelector from "@/components/UnioneSelector.vue";
import HeadquarterSelector from "@/components/HeadquarterSelector.vue";
import HandleCompanyAssociatingModal from "@/components/HandleCompanyAssociatingModal.vue";
import { RoutesNames } from "@/router";
import { formatAtecoCode } from "@/utils/ateco";
import {
  companyAssociateData,
  companyEmployeesData,
  companyActiveData,
} from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { StatusCodes } from "@/utils/status";
import UsersSelector from "@/components/UsersSelector.vue";
import { CompaniesListTypes } from "@/store/modules/data";
import { isCompanyActive, isCompanyCeased, statuses } from "@/utils/company";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    AtecoSelector,
    SelectedAteco,
    UnioneSelector,
    SelectedUnione,
    HandleCompanyAssociatingModal,
    UsersSelector,
    EditAtecoCompanyModal,
    AssociateReferentToCompanyModal,
    HeadquarterSelector,
    EditStatusCompanyModal,
    DeleteCompanyModal,
  },
  watch: {
    options: {
      handler() {
        this.fetchCompanies(false);
      },
      deep: true,
    },
    selectedAteco(val) {
      this.filters.ateco = _.map(val, (ateco) => {
        return ateco.code.replaceAll(".", "");
      });
    },
    selectedUnione(val) {
      this.filters.unione = val;
    },
  },
})
export default class CompaniesTable extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.companies) companies;
  @State((state: StateStore) => state.data.companiesLoading) companiesLoading;
  @State((state: StateStore) => state.data.selectedAteco) selectedAteco;
  @State((state: StateStore) => state.data.selectedUnione) selectedUnione;
  @Getter getRole: (code: string) => Api.Role;
  @Action getCompanies;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation setSelectedHeadquarter;

  @Prop({ default: true })
  showFilters: boolean;

  options = {
    itemsPerPage: 10,
    page: 1,
  };
  total = 0;

  editCompany = null;

  associateFilter = companyAssociateData;
  employeesFilter = companyEmployeesData;
  activeFilter = companyActiveData;
  search = "";
  filters = {
    associated: [],
    employees: [],
    active: [],
    ateco: [],
    unione: [],
    referent: null,
    headquarter: null,
  };

  loading = false;

  showSendingEmailModal = null;
  sendingEmailSubject = "";
  sendingEmailText = "";

  showAssociatingCompanyModal = null;
  showEditAtecoCompanyModal = null;
  showEditStatusCompanyModal = null;
  showAssociateReferentToCompanyModal = null;
  showDeleteCompanyModal = null;

  getModalKey() {
    return Math.random();
  }

  headers = [
    {
      text: "Azienda",
      align: "start",
      sortable: true,
      value: "name",
    },
    {
      text: "Unione Nazionale",
      align: "start",
      sortable: false,
      value: "ateco.des_unione",
    },
    {
      text: "Codice Ateco",
      align: "start",
      sortable: true,
      value: "ateco",
    },
    {
      text: "Referente",
      align: "start",
      sortable: false,
      value: "referent",
    },
    { text: "Azioni", value: "actions", sortable: false, filterable: false },
  ];
  selected = [];
  style: string;

  async mounted() {
    this.initFilters();
    if (!this.isAdmin()) {
      this.headers.pop(); // remove actions column
    }
    await this.fetchCompanies(true);
  }

  async fetchCompanies(resetPagination: boolean) {
    const { page, itemsPerPage } = this.options;
    if (resetPagination) {
      this.options = {
        itemsPerPage: 10,
        page: 1,
      };
    }
    this.total = await this.getCompanies({
      type: CompaniesListTypes.Database,
      text: this.search,
      headquarter: this.filters.headquarter,
      unione: this.filters.unione,
      ateco: this.filters.ateco,
      associated: this.filters.associated.includes(1) ? 1 : 0,
      notAssociated: this.filters.associated.includes(0) ? 1 : 0,
      active: this.filters.active.includes(1) ? 1 : 0,
      notActive: this.filters.active.includes(0) ? 1 : 0,
      employees: this.filters.employees.includes(1) ? 1 : 0,
      notEmployees: this.filters.employees.includes(0) ? 1 : 0,
      pageIndex: page,
      pageSize: itemsPerPage,
    });
  }

  initFilters() {
    this.setSelectedHeadquarter(null);
    this.filters.associated = _.map(this.associateFilter, "value");
    this.filters.employees = _.map(this.employeesFilter, "value");
    this.filters.active = _.map(this.activeFilter, "value");
  }

  getRolesFilter() {
    return [this.getRole(RoleCodes.USER).guid];
  }

  getStatusFilter() {
    return [StatusCodes.USER_REF_AZ];
  }

  isAdmin() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2]);
  }

  hasReferent(company) {
    return company.referents?.length > 0;
  }

  getReferentName(company) {
    if (this.hasReferent(company)) {
      return company.referents[0].name + " " + company.referents[0].surname;
    }
    return "Nessun referente";
  }

  getAtecoCode(company) {
    return formatAtecoCode(company.ateco.code);
  }

  setSending(item) {
    if (_.isNil(item)) {
      this.selected = [];
    } else {
      this.selected = [item];
    }
    this.showSendingEmailModal = item;
  }

  async sendEmail() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation sendEmailCompany(
          $guid: [String]
          $subject: String!
          $text: String!
        ) {
          sendEmailCompany(guid: $guid, subject: $subject, text: $text)
        }
      `,
      variables: {
        guid: _.map(this.selected, "guid"),
        subject: this.sendingEmailSubject,
        text: this.sendingEmailText,
      },
    });
    this.loading = false;
    this.setSending(null);
    if (res.data.sendEmailCompany) {
      this.showSuccess("Email inviata correttamente");
      this.sendingEmailSubject = "";
      this.sendingEmailText = "";
    } else {
      this.showError("Errore invio email");
    }
  }

  handleClick(item) {
    this.$router.push({
      name: RoutesNames.companiesManageDetails,
      params: { companyGuid: item.guid },
    });
  }

  initAssociatingCompany(company) {
    this.editCompany = company;
    this.showAssociatingCompanyModal = true;
  }

  initStatusHandlingCompany(company) {
    this.editCompany = company;
    this.showEditStatusCompanyModal = true;
  }

  initDeleteCompany(company) {
    this.editCompany = company;
    this.showDeleteCompanyModal = true;
  }

  initAtecoHandling(company) {
    this.editCompany = company;
    this.showEditAtecoCompanyModal = true;
  }

  initReferentHandling(company) {
    this.editCompany = company;
    this.showAssociateReferentToCompanyModal = true;
  }

  isActive(code) {
    return isCompanyActive(code);
  }

  isCeased(code) {
    return isCompanyCeased(code);
  }
}
