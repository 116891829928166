
import { getToday } from "@/plugins/moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import WithValidation from "../utils/validation";

@Component({
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
    reset(val) {
      if (val) {
        this.date = null;
      }
    },
  },
})
export default class DatePicker extends Vue {
  @Prop({ default: "Selezionare data" })
  label: string;
  @Prop({ default: false })
  disabled: boolean;
  @Prop({ default: null })
  preSelectedDate: string;
  @Prop({ default: () => [] })
  rules;
  @Prop({ default: false })
  reset: boolean;
  @Prop({ default: null })
  minDate: string;
  @Prop({ default: null })
  maxDate: string;

  $refs!: {
    dateField: WithValidation;
  };

  @Watch("preSelectedDate")
  handlePreSelectedDate(val) {
    if (val != null) {
      this.date = val;
      this.dateFormatted = this.formatDate(val);
    }
  }

  mounted() {
    if (this.preSelectedDate != null) {
      this.date = this.preSelectedDate;
    }
  }

  date = null;
  dateFormatted = null;
  menu = null;

  dateSelected() {
    this.$refs.dateField.resetValidation();
    this.dateFormatted = this.formatDate(this.date);
    this.$emit("selected", this.date);
  }

  formatDate(date) {
    if (!date) return null;

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }
}
