import * as Api from "@/types/graphql";

import _ from "lodash";

export const GENDER_MAN_CODE = "M";
export const GENDER_FEMALE_CODE = "F";
export const GENDER_OTHER_CODE = "G";

export function getReferentName(user: Api.User): string {
  if (user.facilitator == null) {
    return "";
  } else {
    return (
      _.capitalize(user.facilitator.name) +
      " " +
      _.capitalize(user.facilitator.surname)
    );
  }
}

export function getGenderText(genderCode: string): string {
  if (genderCode == GENDER_MAN_CODE) {
    return "Uomo";
  }
  if (genderCode == GENDER_FEMALE_CODE) {
    return "Donna";
  } else return "Altro";
}

export const genderData = [
  { label: "Uomo", value: GENDER_MAN_CODE },
  { label: "Donna", value: GENDER_FEMALE_CODE },
  { label: "Altro", value: GENDER_OTHER_CODE },
];

export const associateData = [
  { label: "Associato", value: 1 },
  { label: "Non Associato", value: 0 },
];

export const companyAssociateData = [
  { label: "Associata", value: 1 },
  { label: "Non Associata", value: 0 },
];

export const companyEmployeesData = [
  { label: "Con dipendenti", value: 1 },
  { label: "Senza dipendenti", value: 0 },
];

export const companyActiveData = [
  { label: "Attiva", value: 1 },
  { label: "Cessata", value: 0 },
];

export const TAX_CODE_REGEX = '^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$'
