
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import * as Api from "@/types/graphql";
import { statuses } from "@/utils/company";
import gql from "graphql-tag";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";

@Component({
  components: {
    Modal,
    Avatar,
    SelectedUsers,
  },
  watch: {},
})
export default class EditStatusCompanyModal extends Vue {
  @Getter getRole: (code: string) => Api.Role;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation resetSelectedUsers;
  @Mutation resetSelectedCompanies;
  @Mutation setSelectedCompanies;

  statusRules = [(v) => !!v || "Selezionare uno stato"];
  selectedStatus = null;

  @Prop()
  company;

  loading = false;

  selectedAteco = null;
  companyStatus = statuses;

  mounted() {
    console.log('mounted')
    if (this.company != null) {
      this.selectedStatus = this.company.company_status;
    }
  }

  async save() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation company_update($guid: ID!, $status: String) {
          updateCompany(guid: $guid, company_status: $status)
        }
      `,
      variables: {
        guid: this.company.guid,
        status: this.selectedStatus,
      },
    });
    this.loading = false;
    if (res.data.updateCompany) {
      this.$emit("success");
      this.showSuccess("Stato azienda modificato correttamente");
      this.onClose();
    } else {
      this.showError("Errore durante modifica Stato azienda");
    }
  }

  onClose() {
    this.$emit("close");
  }
}
