
import { getAreaGroups } from "@/utils/area";
import { genderData } from "@/utils/user";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class GroupsSelector extends Vue {
  items = genderData;

  onChange(gender) {
    this.$emit("selected", gender.value);
  }
}
