
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import * as Api from "@/types/graphql";
import { statuses } from "@/utils/company";
import gql from "graphql-tag";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";

@Component({
  components: {
    Modal,
    Avatar,
    SelectedUsers,
  },
  watch: {},
})
export default class EditStatusCompanyModal extends Vue {
  @Getter getRole: (code: string) => Api.Role;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation resetSelectedUsers;
  @Mutation resetSelectedCompanies;
  @Mutation setSelectedCompanies;

  @Prop()
  company;

  loading = false;

  async save() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation delete_company($guid: String!) {
          deleteCompany(guid: [$guid])
        }
      `,
      variables: {
        guid: this.company.guid,
      },
    });
    this.loading = false;
    if (res.data.deleteCompany) {
      this.$emit("success");
      this.showSuccess("Azienda eliminata correttamente");
      this.onClose();
    } else {
      this.showError("Errore durante eliminazione azienda");
    }
  }

  onClose() {
    this.$emit("close");
  }
}
