
import * as Api from "@/types/graphql";
import { formatAtecoCode } from "@/utils/ateco";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  watch: {
    selectedAtecoState(val, prev) {
      this.selectedAteco = val;
    },
  },
})
export default class AtecoSelector extends Vue {
  @State((state: StateStore) => state.data.selectedAteco)
  selectedAtecoState: Api.Ateco[];
  @Mutation setSelectedAteco;

  loading = false;
  @Prop({ default: false })
  multiple: boolean;

  search = "";
  atecoList = [];
  atecoRules = [(v) => !!v || "Selezionare un codice Ateco"];
  selectedAteco = null;

  async mounted() {
    this.atecoList = [];
    await this.getAtecoList();
    if (this.multiple) {
      this.selectedAteco = [];
    } else {
      this.selectedAteco = null;
    }
  }

  onChange() {
    this.search = "";
    if (this.multiple) {
      this.setSelectedAteco(this.selectedAteco);
    } else {
      this.$emit("selected", this.selectedAteco);
    }
  }

  async getAtecoList() {
    const atecoRes = await this.$apollo.query({
      query: gql`
        query {
          AtecoList {
            code
            des
            unione
            des_unione
            settore
            des_settore
          }
        }
      `,
      fetchPolicy: "no-cache",
    });
    const ateco = _.filter(atecoRes.data.AtecoList, (ateco) => {
      return ateco.code.length == 6;
    });
    this.atecoList = _.map(ateco, (atecoItem) => {
      atecoItem.des = atecoItem.des.trim();
      atecoItem.code = formatAtecoCode(atecoItem.code);
      return atecoItem;
    });
  }

  getMenuProps() {
    return {
      closeOnContentClick: !this.multiple,
    };
  }

  isSelected(ateco) {
    return !_.isNil(
      _.find(this.selectedAtecoState, (a) => {
        return a.code == ateco.code;
      })
    );
  }

  filterAteco(item, queryText) {
    if ((queryText as string)?.length < 3) return false;

    return (
      item.des
        .toString()
        .toLowerCase()
        .includes(queryText.toString().toLowerCase()) ||
      item.code
        .toString()
        .toLowerCase()
        .includes(queryText.toString().toLowerCase())
    );
  }
}
