
import * as Api from "@/types/graphql";
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter, Mutation, State } from "vuex-class";
import gql from "graphql-tag";
import _ from "lodash";
import { Prop } from "vue-property-decorator";
import { RoleCodes } from "@/utils/roles";
import { StatusCodes } from "@/utils/status";

@Component({
  components: {
    Modal,
    Avatar,
    UsersSelector,
    SelectedUsers,
  },
  watch: {},
})
export default class AssociateReferentToCompanyModal extends Vue {
  @Getter getRole: (code: string) => Api.Role;
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Mutation showSuccess;
  @Mutation showError;
  @Action resetSelectedUsers;

  @Prop()
  company;

  loading = false;

  selectedReferent = null;

  mounted() {
    this.resetValue();
  }

  resetValue() {
    this.selectedReferent = null;
  }

  getRolesFilter() {
    return [this.getRole(RoleCodes.USER).guid];
  }

  capitalize(text) {
    return _.capitalize(text);
  }

  async save() {
    this.loading = true;
    const companyRes = await this.$apollo.mutate({
      mutation: gql`
        mutation company_update($guid: ID!, $referent: String) {
          updateCompany(guid: $guid, referent_guid: $referent)
        }
      `,
      variables: {
        guid: this.company.guid,
        referent: this.selectedReferent.guid,
      },
    });
    if (companyRes.data.updateCompany) {
      const userRes = await this.$apollo.mutate({
        mutation: require("@/graphql/setUserStatus.graphql"),
        variables: {
          guid: this.selectedReferent.guid,
          role: this.selectedReferent.role.guid,
          status: this.getStatus(StatusCodes.USER_REF_AZ).guid,
        },
      });

      this.loading = false;
      if (userRes.data.changeStatusUser) {
        this.$emit("success");
        this.showSuccess("Referente aziendale modificato correttamente");
        this.onClose();
      } else {
        this.showError("Errore durante modifica Referente Aziendale");
      }
    } else {
      this.showError("Errore durante modifica Referente Aziendale");
    }
  }

  onClose() {
    this.resetValue();
    this.resetSelectedUsers();
    this.$emit("close");
  }
}
