
import * as Api from "@/types/graphql";
import { getAreaGroups } from "@/utils/area";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  watch: {
    selectedArea(val) {
      this.groups = getAreaGroups(val);
    },
  },
})
export default class GroupsSelector extends Vue {
  @State((state: StateStore) => state.data.selectedArea)
  selectedArea: Api.CodeDecode;
  @State((state: StateStore) => state.data.selectedGroups)
  selectedGroupsState: Api.CodeDecodeChild[];
  @Mutation setSelectedGroups;

  loading = false;

  search = "";
  groups = [];
  selectedGroups = [];

  mounted() {
    this.groups = getAreaGroups(this.selectedArea);
  }

  isSelected(group) {
    return _.findIndex(this.selectedGroupsState, {guid: group.guid}) > -1;
  }

  filter(item, queryText) {
    if ((queryText as string)?.length < 3) return false;

    return item.des
      .toString()
      .toLowerCase()
      .includes(queryText.toString().toLowerCase());
  }

  onChange(groups) {
    this.setSelectedGroups(
      _.filter(groups, (group) => {
        return group instanceof Object;
      })
    );
    this.$emit(
      "selected",
      _.map(groups, (group) => {
        return group.guid;
      })
    );
  }
}
