
import * as Api from "@/types/graphql";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";

@Component({
  components: {},
  watch: {
    selectedGroups(val) {
      if (_.isUndefined(this.customList)) {
        this.initGroupsList(val);
      }
    },
    customList(val) {
      this.items = val;
    },
  },
})
export default class SelectedGroups extends Vue {
  @State((state: StateStore) => state.data.selectedGroups)
  selectedGroups: Api.CodeDecodeChild[];
  @Mutation removeSelectedGroup;
  @Mutation resetSelectedGroups;

  @Prop()
  customList: Api.CodeDecodeChild[];
  @Prop({ default: true })
  enableDelete: boolean;

  items = [];

  initGroupsList(groups: Api.CodeDecodeChild[]) {
    this.items = groups;
  }

  mounted() {
    if (_.isUndefined(this.customList)) {
      this.initGroupsList(this.selectedGroups);
    } else {
      this.initGroupsList(this.customList);
    }
  }

  beforeDestroy() {
    this.resetSelectedGroups();
  }
}
